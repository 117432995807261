import unit from 'fela-plugin-unit';
import namedKeys from 'fela-plugin-named-keys';

const namedKeysPlugin = namedKeys({
    min576px: '@media (min-width: 576px)',
    max576px: '@media (max-width: 576px)',
    min768px: '@media (min-width: 768px)',
    max768px: '@media (max-width: 768px)',
    min992px: '@media (min-width: 992px)',
    max992px: '@media (max-width: 992px)',
    min1125px: '@media (min-width: 1125px)',
    max1125px: '@media (max-width: 1125px)',
});

export default {
    plugins: [unit(), namedKeysPlugin],
    enhancers: [
        /* add your enhancers here */
    ],
    devMode: process.env.NODE_ENV !== 'production',
};
